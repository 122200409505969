import React from 'react'
import { graphql } from 'gatsby'
import { HtmlDiv } from '../utils/types'
import styled from 'styled-components'
import Article, { TableOfContents } from '../models/Article'
import Manual from '../components/Core/Content/Manual/Manual'
import Layout from '../components/MarketingSite/Layout/Layout'

const DeveloperDocsTemplate = ({ data }: DeveloperDocsPageProps) => {
  const { body, tableOfContents } = data.mdx
  const article = new Article(body, 0, 0, tableOfContents)
  return (
    <Layout
      seoTitle="Developer Docs"
      seoDescription="The source of truth for everything Dev Simulator"
    >
      <Manual article={article} />
    </Layout>
  )
}

interface DeveloperDocsPageProps extends HtmlDiv {
  data: { mdx: { body: string; tableOfContents: TableOfContents } }
}

export default styled(DeveloperDocsTemplate)``

export const query = graphql`
  query DeveloperDocsPageQuery {
    mdx(frontmatter: { slug: { eq: "developer-docs" } }) {
      body
      tableOfContents
    }
  }
`
